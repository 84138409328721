import React from 'react';
import { FaBookmark } from 'react-icons/fa';
import ProjectProvider from '../../providers/ProjectProvider';
import ProjectLine from './ProjectLine';
import Window from '../Window';
import CollapseButton from '../CollapseButton';
class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event) {
        event.currentTarget.blur();
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    render() {
        const collapsedCount = 2;
        let projects = ProjectProvider.getAll();
        let displayCount = this.state.collapsed ? Math.min(2, projects.length) : projects.length;
        return (React.createElement(Window, null,
            React.createElement("h2", null,
                React.createElement(FaBookmark, null),
                " Projects"),
            projects.slice(0, displayCount).map(projectItem => React.createElement(ProjectLine, { key: projectItem.title, projectItem: projectItem })),
            React.createElement(CollapseButton, { collapsed: this.state.collapsed, onClick: this.handleClick, count: projects.length - collapsedCount })));
    }
}
export default Projects;
