import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../styles/main.scss';
import Header from './Header';
import HomePage from './HomePage';
import Creations from './Creations';
import Resume from './Resume';
import UnknownPage from './UnknownPage';
import Footer from './Footer';
const App = () => (React.createElement(React.Fragment, null,
    React.createElement(Helmet, { titleTemplate: "%s - Nicolas Jullien" }),
    React.createElement(Router, null,
        React.createElement(Header, null),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/", component: HomePage }),
            React.createElement(Route, { path: "/creations", component: Creations }),
            React.createElement(Route, { path: "/resume", component: Resume }),
            React.createElement(Route, { component: UnknownPage })),
        React.createElement(Footer, null))));
export default App;
