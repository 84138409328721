import React from 'react';
const Window = (props) => {
    let className = "wdw wdw-" + (props.xl ? "xl" :
        props.lg ? "lg" :
            props.md ? "md" :
                props.sm ? "sm" :
                    props.xs);
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "cut-line" }, props.children)));
};
export default Window;
