import React from 'react';
import { FaComment } from 'react-icons/fa';
const Languages = () => (React.createElement(React.Fragment, null,
    React.createElement("h5", null,
        React.createElement(FaComment, null),
        " Languages"),
    React.createElement("ul", null,
        React.createElement("li", null, "French (native speaker)"),
        React.createElement("li", null, "English (advanced, TOEIC: 930)"),
        React.createElement("li", null, "Spanish (basic knowledge)"))));
export default Languages;
