import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PersonalDetails from './resume/PersonalDetails';
import KeySkills from './resume/KeySkills';
import WorkExperience from './resume/WorkExperience';
import Education from './resume/Education';
import Projects from './resume/Projects';
import InterestsAndHobbies from './resume/InterestsAndHobbies';
import { Helmet } from 'react-helmet';
const Resume = () => (React.createElement(React.Fragment, null,
    React.createElement(Helmet, null,
        React.createElement("title", null, "Resume")),
    React.createElement(Row, { className: "mx-3" },
        React.createElement(Col, { xl: 3, lg: 4 },
            React.createElement(PersonalDetails, null),
            React.createElement(KeySkills, null)),
        React.createElement(Col, { xl: 9, lg: 8 },
            React.createElement(WorkExperience, null),
            React.createElement(Education, null),
            React.createElement(Projects, null),
            React.createElement(InterestsAndHobbies, null)))));
export default Resume;
