import React from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
const CollapseButton = ({ collapsed, onClick, count }) => {
    let icon = collapsed ? React.createElement(FaPlus, null) : React.createElement(FaMinus, null);
    let text = collapsed ? "Show more (" + count + ")" : "Show less";
    let helper = collapsed ?
        "Click to show the " + (count > 1 ? count + " remaining elements" : "remaining element.") :
        "Click to hide the " + (count > 1 ? count + " last elements" : "last element.");
    return (React.createElement("button", { className: "collapser", onClick: onClick, title: helper },
        icon,
        " ",
        text));
};
export default CollapseButton;
