import React from 'react';
import { FaBriefcase } from 'react-icons/fa';
import WorkExperienceLine from './WorkExperienceLine';
import WorkExperienceProvider from '../../providers/WorkExperienceProvider';
import Window from '../Window';
import CollapseButton from '../CollapseButton';
class WorkExperience extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event) {
        event.currentTarget.blur();
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    render() {
        const collapsedCount = 2;
        let experiences = WorkExperienceProvider.getAll();
        let displayCount = this.state.collapsed ? Math.min(collapsedCount, experiences.length) : experiences.length;
        return (React.createElement(Window, null,
            React.createElement("h2", null,
                React.createElement(FaBriefcase, null),
                " Work experience"),
            experiences.slice(0, displayCount).map(workExperienceItem => (React.createElement(WorkExperienceLine, { key: workExperienceItem.description, workExperienceItem: workExperienceItem }))),
            React.createElement(CollapseButton, { collapsed: this.state.collapsed, onClick: this.handleClick, count: experiences.length - collapsedCount })));
    }
}
export default WorkExperience;
