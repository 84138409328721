import React from 'react';
import { Helmet } from 'react-helmet';
import { FaRegFrown } from 'react-icons/fa';
import Window from './Window';
const Creations = () => (React.createElement(React.Fragment, null,
    React.createElement(Helmet, null,
        React.createElement("title", null, "Creations")),
    React.createElement(Window, { sm: true },
        React.createElement("h2", null,
            React.createElement(FaRegFrown, null),
            " Work in progress"),
        React.createElement("p", null, "This page will come very soon! Stay tuned."))));
export default Creations;
