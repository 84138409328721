import React from 'react';
import { FaBook } from 'react-icons/fa';
import EducationLine from './EducationLine';
import EducationProvider from '../../providers/EducationProvider';
import Window from '../Window';
import CollapseButton from '../CollapseButton';
class Education extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event) {
        event.currentTarget.blur();
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    render() {
        const collapsedCount = 2;
        let education = EducationProvider.getAll();
        let displayCount = this.state.collapsed ? Math.min(collapsedCount, education.length) : education.length;
        return (React.createElement(Window, null,
            React.createElement("h2", null,
                React.createElement(FaBook, null),
                " Education"),
            education.slice(0, displayCount).map(educationItem => (React.createElement(EducationLine, { key: educationItem.title, educationItem: educationItem }))),
            React.createElement(CollapseButton, { collapsed: this.state.collapsed, onClick: this.handleClick, count: education.length - collapsedCount })));
    }
}
export default Education;
