import React from 'react';
import { FaCode } from 'react-icons/fa';
const Development = () => (React.createElement(React.Fragment, null,
    React.createElement("h5", null,
        React.createElement(FaCode, null),
        " Development"),
    React.createElement("ul", null,
        React.createElement("li", null,
            "Software",
            React.createElement("p", null, "C++, C, C#, Java, OpenGL")),
        React.createElement("li", null,
            "Web",
            React.createElement("p", null, "HTML5, CSS3, PHP7, JavaScript, TypeScript"),
            React.createElement("p", null, "Symfony, Yii, Node.js, React")),
        React.createElement("li", null,
            "Tools",
            React.createElement("p", null, "Git, GitLab")))));
export default Development;
