import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaCode, FaReact, FaGitlab, FaNodeJs } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.update = this.update.bind(this);
    }
    update() {
        this.forceUpdate();
    }
    render() {
        const randomEmoji = HomePage.emojis[Math.floor(Math.random() * HomePage.emojis.length)];
        return (React.createElement(React.Fragment, null,
            React.createElement(Helmet, null,
                React.createElement("title", null, "Home")),
            React.createElement(Row, { className: "mx-3" },
                React.createElement(Col, { md: 8 },
                    React.createElement("div", { className: "wdw" },
                        React.createElement("div", { className: "cut-line" },
                            React.createElement("h1", null, "Developer"),
                            React.createElement("div", { className: "row mb-3" },
                                React.createElement("div", { className: "col-sm" },
                                    React.createElement("h4", null, "Sofwares & video games"),
                                    React.createElement("p", null, "C++, Java, C#, OpenGL, ...")),
                                React.createElement("div", { className: "col-sm" },
                                    React.createElement("h4", null, "Web"),
                                    React.createElement("p", null, "PHP, Fullstack, Symfony, Yii2, JS, TS, React, ..."))),
                            React.createElement("h4", null, "Some creations"),
                            React.createElement("p", null,
                                React.createElement("strong", null, "TMOne"),
                                " : Reboot of old Trackmania games"),
                            React.createElement("p", null,
                                React.createElement("strong", null, "Planetless"),
                                " : Global Game Jam 2019 game made in 48 hours"),
                            React.createElement("p", null,
                                React.createElement("strong", null, "WorldXGen"),
                                " : C++ simple mountain map generator"))),
                    React.createElement("div", { className: "wdw" },
                        React.createElement("div", { className: "cut-line" },
                            React.createElement("h1", null, "Creator"),
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-sm" },
                                    React.createElement("h4", null, "Photography"),
                                    React.createElement("p", null, "Nature, landscapes, vegetation, ..."),
                                    React.createElement("a", { title: "Instagram page", target: "blank", href: "https://instagram.com/nerpson" }, "See on Instagram")),
                                React.createElement("div", { className: "col-sm" },
                                    React.createElement("h4", null, "Musics"),
                                    React.createElement("p", null, "EDM, Drum & Bass, Chill, ..."),
                                    React.createElement("a", { title: "Soundcloud page", target: "blank", href: "https://soundcloud.com/nerpson" }, "Listen on Soundcloud")),
                                React.createElement("div", { className: "col-sm" },
                                    React.createElement("h4", null, "Videos"),
                                    React.createElement("p", null, "Video games montages, contemplative videos, ..."),
                                    React.createElement("a", { title: "Youtube page", target: "blank", href: "https://youtube.com/NerpsonGaming" }, "Watch on Youtube")))))),
                React.createElement(Col, { md: 4 },
                    React.createElement("div", { className: "wdw profile-picture-bg" }),
                    React.createElement("div", { className: "wdw" },
                        React.createElement("div", { className: "cut-line" },
                            React.createElement("h1", null, "Contact me"),
                            React.createElement("p", null,
                                React.createElement(FaEnvelope, null),
                                " me@nicolasjullien.fr"),
                            React.createElement("p", null,
                                React.createElement(FaPhone, null),
                                " Ask it by email ^^"))),
                    React.createElement("div", { className: "wdw" },
                        React.createElement("div", { className: "cut-line" },
                            React.createElement("h1", null, "About this website"),
                            React.createElement(Row, null,
                                React.createElement(Col, { xl: 7 },
                                    React.createElement("p", null,
                                        React.createElement(FaNodeJs, null),
                                        " Project based on Node.js"),
                                    React.createElement("p", null,
                                        React.createElement(FaCode, null),
                                        " Coded in TypeScript"),
                                    React.createElement("p", null,
                                        React.createElement(FaReact, null),
                                        " Using the React framework"),
                                    React.createElement("p", null,
                                        React.createElement(FaGitlab, null),
                                        " Git repository on GitLab")),
                                React.createElement(Col, { xl: 5 },
                                    React.createElement("p", { className: "big-emoji d-none d-xl-block", title: "Click me", onClick: this.update },
                                        "\uD83D\uDC48",
                                        randomEmoji,
                                        "\uD83D\uDC4D"),
                                    React.createElement("p", { className: "big-emoji d-xl-none", title: "Click me", onClick: this.update },
                                        "\uD83D\uDC46",
                                        randomEmoji,
                                        "\uD83D\uDC4D")))))))));
    }
}
HomePage.emojis = [
    "👀",
    "😏",
    "😳",
    "😯",
    "🤩",
    "😗",
    "😁",
    "😇"
];
export default HomePage;
