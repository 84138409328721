import React from 'react';
import { FaGripHorizontal } from 'react-icons/fa';
const Softwares = () => (React.createElement(React.Fragment, null,
    React.createElement("h5", null,
        React.createElement(FaGripHorizontal, null),
        " Softwares"),
    React.createElement("ul", null,
        React.createElement("li", null,
            "Programming",
            React.createElement("p", null, "Visual Studio, IntelliJ, VS Code, ...")),
        React.createElement("li", null,
            "Audiovisual",
            React.createElement("p", null, "Adobe Premiere")),
        React.createElement("li", null,
            "Graphism",
            React.createElement("p", null, "Adobe Photoshop, InDesign")),
        React.createElement("li", null,
            "Microsoft Office",
            React.createElement("p", null, "Word, Excel, Powerpoint")))));
export default Softwares;
