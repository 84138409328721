import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import Tags from './Tags';
const JobDescription = ({ title, description, tags }) => (React.createElement("div", { className: "job-description" },
    React.createElement("h5", null,
        React.createElement(FaAngleRight, null),
        " ",
        React.createElement(ReactMarkdown, { source: title, skipHtml: true, allowedTypes: ['text', 'emphasis'], unwrapDisallowed: true })),
    React.createElement(Tags, { tags: tags }),
    React.createElement(ReactMarkdown, { source: description, escapeHtml: false })));
export default JobDescription;
