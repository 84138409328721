import React from 'react';
import { FaUser, FaPhone } from 'react-icons/fa';
import Window from '../Window';
const PersonalDetails = () => (React.createElement(Window, null,
    React.createElement("h2", null,
        React.createElement(FaUser, null),
        " Personal details"),
    React.createElement("p", null, "24 years old"),
    React.createElement("p", null, "Driving licence"),
    React.createElement("h5", null,
        React.createElement(FaPhone, null),
        " Contact"),
    React.createElement("p", null, "me@nicolasjullien.fr")));
export default PersonalDetails;
