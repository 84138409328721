import React from 'react';
import { FaHeart } from 'react-icons/fa';
import InterestProvider from '../../providers/InterestProvider';
import Window from '../Window';
const InterestsAndHobbies = () => (React.createElement(Window, null,
    React.createElement("h2", null,
        React.createElement(FaHeart, null),
        " Interests and hobbies"),
    InterestProvider.getAll().map(interest => React.createElement("p", { key: interest }, interest))));
export default InterestsAndHobbies;
