import React from 'react';
import { Link, withRouter } from 'react-router-dom';
const Header = () => (React.createElement("header", null,
    React.createElement("div", { className: "cut-line auto-hide" },
        React.createElement("div", { id: "header-box" },
            React.createElement("h1", null, "Nicolas Jullien"),
            React.createElement("nav", null,
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/" }, "Home")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/creations" }, "Creations")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/resume" }, "Resume")))))),
    React.createElement("div", { className: "gradient-line" })));
export default withRouter(Header);
