import React from 'react';
import { FaClipboardList } from 'react-icons/fa';
import Window from '../Window';
import Development from './key_skills/Development';
import OperatingSystems from './key_skills/OperatingSystems';
import Softwares from './key_skills/Softwares';
import Languages from './key_skills/Languages';
const KeySkills = () => (React.createElement(Window, null,
    React.createElement("h2", null,
        React.createElement(FaClipboardList, null),
        " Key skills"),
    React.createElement(Development, null),
    React.createElement(OperatingSystems, null),
    React.createElement(Softwares, null),
    React.createElement(Languages, null)));
export default KeySkills;
